<script setup lang="ts">
const props = defineProps<{
  data: {
    items: Item[]
  }
}>()

interface Item {
  link: string
  text: string
  background: string
  imageLink: string
}

const {
  t,
} = useI18n()
</script>

<template>
  <div class="mx-auto max-w-3xl pb-4 lg:max-w-[88rem] lg:px5 lg:pb3 lg:pt3 md:max-w-4xl sm:px-6 sm:px4 sm:py-12 sm:pb12 sm:pt6">
    <div class="grid grid-cols-1 gap-4 p-4 md:grid-cols-2">
      <NLink
        v-for="(item, index) in data.items" :key="index" :to="item.link"
        class="relative overflow-hidden no-underline"
      >
        <div class="flex items-center rounded-sm p-13 md:p-25" :class="[item.background]">
          <div class="flex items-center text-center">
            <img
              :src="item.imageLink" :alt="item.text"
              class="absolute bottom-0 right-0 mx-auto drop-shadow-lg transition hover:translate-y-5 hover:scale-120"
            >
            <span class="text-3xl uppercase">{{ item.text }}</span>
          </div>
        </div>
      </NLink>
    </div>
  </div>
  <div class="mx-4 max-w-3xl md:mx-auto lg:max-w-[88rem] lg:px9 lg:pt4 md:max-w-4xl sm:px-6 sm:px4 sm:py-12 sm:py4">
    <div class="w-full flex flex-col border border-gray-300 rounded-md bg-gray-50 p-6 md:flex-row">
      <div
        class="separator flex items-center justify-center border-b-1 border-b-gray-200 pb2 pr-4 md:w-33% space-x-2 md:border-b-none md:pb-0"
      >
        <NIcon icon="i-heroicons:arrows-right-left" n="xl" class="n-button-icon" />
        <span class="text-gray-700">{{ t('product.100_days_return.label') }}</span>
      </div>
      <div
        class="separator flex items-center justify-center border-b-1 border-b-gray-200 pb2 pr-4 pt2 md:w-33% space-x-2 md:border-b-none md:pb-0 md:pt-0"
      >
        <NIcon icon="i-heroicons:clock" n="xl" class="n-button-icon" />
        <span class="text-gray-700">{{ t('product.express_shipping.label') }}</span>
      </div>
      <div class="flex items-center justify-center pr-4 pt-2 md:w-33% space-x-2 md:pt-0">
        <NuxtLinkLocale to="/pages/doprava-dopravci-a-zpusoby-doruceni">
          <NIcon icon="i-heroicons:truck" n="xl" class="n-button-icon" />
          <span class="mt1 hidden text-gray-700 lg:inline">Doba dodání: 5–30 dní <NIcon icon="i-heroicons:information-circle" /></span>
          <span class="mt1 text-gray-700 lg:hidden">Doprava zdarma </span>
        </NuxtLinkLocale>
      </div>
    </div>
  </div>
</template>

<style>
@media (max-width: 560px) {
  .separator {
    border-right: none !important;
  }

}

.separator {
  border-right: 1px solid #d1d5db;
}
</style>
